import { AppConfig } from './../app.config';
import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HubConnection } from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";
import { Observable, of } from "rxjs";
import { SignalRConnectionInfo } from "./signalr-connection-info.model";
import { map } from "rxjs/operators";
import { Subject } from "rxjs";
import { Message } from "src/app/models/message";
import { environment } from "src/environments/environment";

@Injectable()
export class SignalRService {
  private readonly _http: HttpClient;
  private hubConnection: HubConnection;
  messages: Subject<string> = new Subject();
  signalReceived = new EventEmitter<string>();


  constructor(http: HttpClient,
              private config: AppConfig) {
    this._http = http;
  }

  private async getConnectionInfo(): Promise<Observable<SignalRConnectionInfo>> {
    const apiUrl = await this.config.get("apiUrl");

    let requestUrl = apiUrl + 'v2/turno-hub/negotiate?negotiateVersion=1';
    let body: Object = {};
    return this._http.post<SignalRConnectionInfo>(requestUrl, body);
  }

  async init(url: string) {
    const apiUrl = await this.config.get("apiUrl") + "v2/turno-hub";

    (await this.getConnectionInfo()).subscribe(async (info) => {
      console.log("info es", info);

      let options = {
        transport: signalR.HttpTransportType.LongPolling
      };

      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(apiUrl, options)
        .configureLogging(signalR.LogLevel.None)
        .withAutomaticReconnect({nextRetryDelayInMilliseconds: retryContext => {
            if (retryContext.elapsedMilliseconds < 5000){
              return Math.random() * 500;
            } else {
              return null;
            }
          }
        })
        .build();

      this.hubConnection.start()
                        .then(() => console.log('Connection started'))
                        .catch((err) => console.error(err.toString()));
      const idEmpresa = await this.config.get("idEmpresa");
      const pantalla = "pantalla" + url;
      this.hubConnection.on(pantalla, (data: any) => {
        this.signalReceived.emit(data);
        console.log('pantalla es', data);
      });
    });

  }
  async send(message: string) {
    const apiUrl = await this.config.get("apiUrl") + "v2/turno-hub/";
    console.log("called2");
  }

  receieve(message: Message): Message[] {
    // read in from local strorage
    const messages = this.load();
    messages.unshift(message);
    localStorage.setItem("messages", JSON.stringify(messages));
    return messages;
  }

  load(): Message[] {
    const messagesLocal = localStorage.getItem("messages");
    let messagesResponse = [];
    if (messagesLocal !== null) {
      messagesResponse = JSON.parse(messagesLocal);
    }
    return messagesResponse;
  }

  clear(): Observable<void> {
    const messagesLocal = localStorage.getItem("messages");
    let messagesResponse = [];
    if (messagesLocal !== null) {
      localStorage.setItem("messages", JSON.stringify(messagesResponse));
    }
    return of(null);
  }
}
