import { DecryptService } from './../services/decrypt.service';
import { TokenService } from './../services/token.service';
import { TitleService } from './../services/title.service';
import { AppConfig } from './../app.config';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { SignalRConnectionInfo } from './../services/signalr-connection-info.model';
import { HubConnection } from '@microsoft/signalr';
import { HttpClient } from '@angular/common/http';
import { Llamado } from './../models/llamado';
import { SignalRService } from './../services/signalr.service';
import { AfterViewInit, Component, OnInit, Renderer2  } from '@angular/core';
import {Howl, Howler} from '../../assets/howler/howler.js';
import { ActivatedRoute } from '@angular/router';
import * as signalR from "@microsoft/signalr";

const {Howl, Howler} = require('../../assets/howler/howler.js');

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoComponent implements OnInit{

  bShowLlamado = false;
  bEmpty       = true;
  bBlink       = false;
  medico: string = '';
  piso: string = '';
  paciente: string = '';
  box: string = '';
  idPantalla: string = '';
  pac:    Llamado[] = new Array();
  pacAux: Llamado[] = new Array();
  maxRow: number = 0;
  timeData: number = 3;
  configTimer: any;
  constructor(private renderer: Renderer2,
              private signalRService: SignalRService,
              private activatedRoute: ActivatedRoute,
              private titleService: TitleService,
              private config: AppConfig,
              private tokenService: TokenService,
              private decryptService: DecryptService)
  {
    this.configTimer = {leftTime: this.timeData, demand:true};
  }

  ngOnInit() {
  //  this.renderer.addClass(document.body, 'background-none');
  //  this.renderer.removeClass(document.body, 'llamado-bg');

    console.log(this.activatedRoute.snapshot.paramMap.get('id'));
    this.idPantalla = this.activatedRoute.snapshot.paramMap.get('id');
    this.titleService.dispBut.subscribe((value) => {
      this.bShowLlamado = value;
      console.log('value', value)
    });
    this.maxRow = this.config.get("maxRow");
    console.log('Cantidad de registros máximos ', this.maxRow);
    this.getData(this.idPantalla)
  }

    async getData(pantalla: string){
    await this.signalRService.init(pantalla);
    this.signalRService.signalReceived.subscribe({
      next: async message => {
        console.log('inicio mensaje test', message);
        let response = JSON.parse(this.decryptService.getDataFromScreen(message));
        if(response['estado'] === 'L') {
          let paciente:Llamado = new Llamado;
          let nomPac = response['nombreusuario'].split(' ');
          paciente.paciente = (nomPac[0] + ' ' + (nomPac.length == 3 ? nomPac[1].substring(0,1) : nomPac[2].substring(0,1)) + (nomPac.length == 3 ? nomPac[2].substring(0,1) : nomPac[3].substring(0,1))).toUpperCase();
          paciente.medico = response['nombremedico'];
          paciente.box =  response['box'];
          paciente.piso = response['piso'];
          paciente.id = response['IdMedic'];
          paciente.idx = new Date().getTime();
          await this.fillArray(paciente);
          var sound = new Howl({
            src: '../../assets/mp3/Llamado.mp3',
            volume: 0.5,
          });
          sound.play();
          this.timer('S');
         // this.titleService.disableButton(true);
        }
        console.log('fin Mensaje');
      }});
    this.listarPacientes(this.idPantalla);
    console.log(this.pacAux);
  }

  fillArray(pac:Llamado){
    let pacData = this.pac.find(i => i.id === pac.id);
    if (pacData === undefined){
      if (this.pac.length == this.maxRow) {
        console.log('borrando ultimo registro');
        this.pac.pop();
      }
      this.pacAux.push(pac);
      this.pacAux = this.pacAux.sort(function(a,b){
        return (a.idx > b.idx ? -1 : 1);
      });
      this.pac = this.pacAux;
     /* this.medico = pac.medico;
      this.piso = pac.piso.toString();
      this.paciente = pac.paciente;
      this.box = pac.box.toString();*/
      this.bEmpty = this.pac.length === 0;
    }
  }


  async listarPacientes (id: string){
    let response = await this.tokenService.listarPacientes(id);
    console.log(response);
    if (response !== undefined && response.length > 0 ){
      for (let i = 0; i < response.length ; i++){
        if (this.pac.length == this.maxRow)
          break;
        let paciente:Llamado = new Llamado;
        if (!this.isToday(new Date(response[i]["fecha"])))
          continue;
        let nomPac = response[i]['nombreusuario'].split(' ');
        if (nomPac[0] !== '')
          paciente.paciente = (nomPac[0] + ' ' + (nomPac.length == 3 ? nomPac[1].substring(0,1) : nomPac[2].substring(0,1)) + (nomPac.length == 3 ? nomPac[2].substring(0,1) : nomPac[3].substring(0,1))).toUpperCase();
        else
          paciente.paciente = '';
        paciente.medico = response[i]['nombremedico'];
        paciente.box =  response[i]['box'];
        paciente.piso = response[i]['piso'];
        paciente.id = response[i]['idMedic'];
        paciente.idx = new Date(response[i]['fechallamado']).getTime();
        await new Promise(f => setTimeout(f, 10));
        this.fillArray(paciente);
      }
    }
  }

  isToday(someDate:Date){
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  start(){
    this.configTimer = {leftTime: this.timeData, demand: false, };
    this.bBlink = true;
  }

  stop(){
    this.configTimer = {leftTime: this.timeData, demand: true };
    this.bBlink = false;
  }

  handleEvent(event){
    if(event.action === 'done'){
      console.log('Fin Timer');
      //anular turno de sistema;
      this.timer('E');
    }
    console.log(event);
  }

  timer(action){
    if (action === 'S') {
      console.log('Reinicio blink');
      this.start();
    }
    else if (action === 'E') {
      console.log('Finalizo blink');
      this.stop();
    }
  }
}
