<div class="container-fluid pl-0 pr-0" *ngIf="!bShowLlamado">
    <div class="row text-center box-container" *ngIf="!bEmpty">
        <div class="col-11 mx-auto text-center bg-green mb-3">
            <h1 class="title-at">LLAMADO A BOX</h1>
        </div>
        <div class="col-12 text-center" style="display: none;">
            <countdown #cd [config]="configTimer" (event)="handleEvent($event)"></countdown>
        </div>
        <div class="col-11 mx-auto pl-0 pr-0" *ngFor="let paciente of pac;  let i = index">
            <div class="row mx-auto text-center box-info">
                <!--items-->
                <div class="col-12 color-1  mb-3 mt-1" [ngClass]="{'blink' : (i == 0 && bBlink)}" *ngIf="i===0">
                    <div class="row">
                        <div class="col-9 box-1">
                            <div class="row">
                                <div class="col-12">
                                    <div class="text-pers mt-1 mb-2">
                                        PACIENTE
                                    </div>
                                </div>
                                <div class="col-12 text-info-box-1 display-3 mt-n2 font-weight-bold pb-3 text-left pl-5 pr-5">
                                    {{paciente.paciente}}
                                    <hr class="line-llamado">
                                </div>
                            </div>
                        </div>
                        <div class="col-3 box-2">
                            <div class="row">
                                <div class="col-12">
                                    <div class="text-pers mt-1 mb-2">
                                        BOX
                                    </div>
                                </div>
                                <div class="col-12 display-3  mt-n2 pb-3 font-weight-bold">
                                    {{paciente.box}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 color-1  mb-3 mt-1" *ngIf="i!==0">
                    <div class="row mt-3 ">
                        <div class="col-12 box-1 pt-3">
                            <div class="row pt-3 pb-3">
                                <div class="col-9 text-info-box-1 display-3  mt-n2 pb-3 text-left pl-5 pr-5">
                                    {{paciente.paciente}}
                                    <hr class="line-llamado">
                                </div>
                                <div class="col-3 display-3  mt-n2 pb-3 ">
                                    {{paciente.box}}
                                    <hr class="line-llamado">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/> <br *ngIf="i===0"/><br *ngIf="i===0"/>
        </div>
    </div>
</div>

<!-- <img src="../assets/img/back-img.png" class="img-footer"> -->
